<template>
	<div id="app">

		<router-view />
	</div>
</template>
<script>
	export default {

		data() {
			return {
				
			}
		},
		
		mounted() {

			window.scrollTo(0, 0); /*主题切换*/
			let theme = sessionStorage.getItem("theme");
			if (theme == null) {
				theme = "light";
			}
			document.documentElement.dataset.theme = theme;
			/*END*/
		},

		methods: {}
	}
</script>

<style>
.el-message {
  z-index: 100000002 !important;
}
*{
	margin: 0;
	padding: 0;
}
</style>