<template>
	<div>
		<navt :select="select"></navt>
		<div class="newbox_b_pc">
			<div canplay="banner_pc" style="margin-top: 80px;">
				<el-carousel height="550px" :autoplay="true" :loop="true" :interval="2000" arrow="always">
					<el-carousel-item style="width: 100%;
				height: 550px;" v-for="item in bannerlist" :key="item.id">
						<img style="width: 100%;
				height: 550px; object-fit: cover;object-position: top;" @click="goToUrl(item.url)" :src="item.cover" alt="" />

					</el-carousel-item>
				</el-carousel>
			</div>
			<!-- <div class="swiper-container swiper-container-fluid">
				<div class="swiper-wrapper">
					<div v-for="(item,index) in bannerlist" :key="index" class="swiper-slide"><img
							@click="goToUrl(item.url)" :src="item.cover" alt="" /></div>
				</div>
				<div class="swiper-pagination" style="color: red;"></div>
			</div> -->
			<div class="saicheng">
				<img @click="scrollToLeft()" class="saicheng_left" src="../../assets/firstpage/left.png" alt="" />
				<div style=" width: 86%;overflow-x: auto;">
					<div ref="scrollContainer" class="saicheng_list">
						<div class="saicheng_item" @click="bf(item.MatchID)" v-for="(item,index) in Upcominglist"
							:key="index">
							<div class="saicheng_jieshao">

								<div v-if="item.NowPlaying==1" style="color: #f9952e;">已开始</div>
								<div v-if="item.NowPlaying==0">未开始</div>
								<div>{{item.time}}</div>
								<div>

									<img src="../../assets/live/sd.png" alt="" />
								</div>
							</div>
							<div class="saicheng_time">
								{{item.LeagueCH}}
							</div>
							<div class="schedule_ball">{{item.TypeName}}</div>
							<div class="schedule_b_video_vs_hv">
								<div class="countryname" style="margin-right: 3px;text-align: right;">{{item.HomeCH}}
								</div>
								<div v-if="item.home_img"><img class="flag" :src="item.home_img" alt="" /></div>
								<img class="vsimg" src="../../assets/firstpage/vs.png" alt="" />
								<div v-if="item.away_img"><img class="flag" :src="item.away_img" alt="" /></div>

								<div class="countryname" style="margin-left: 3px;text-align: left;">{{item.AwayCH}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div @click="del()" class="saicheng_more">
					<img src="../../assets/firstpage/rl.png" alt="" srcset="" />
					<div>全部赛程</div>
				</div>
				<img @click="scrollRight" class="saicheng_right" src="../../assets/firstpage/right.png" alt="" />
			</div>
		</div>
		<bottom></bottom>
	</div>
</template>

<script>
	import navt from '@/components/navtop'
	import bottom from '@/components/bottom'
	import Swiper from 'swiper'; // 注意引入的是Swiper
	import {
		Message
	} from 'element-ui';
	import 'swiper/css/swiper.min.css' // 注意这里的引入
	import {
		info,
		banner,
		indeexUpcoming,
		Dictinfo
	} from "../../../api/home.js";
	export default {
		metaInfo: {
			link: [{
				rel: 'canonical',
				href: 'https://www.yylive1.com/'
			}]
		},
		components: {
			navt,
			bottom,
		},
		data() {
			return {
				bannerlist: [],
				Upcominglist: [],
				select: 1,
				searchlist: [],
				code: "",
			}
		},
		beforeDestroy() {
			if (this.swiper) {
				this.swiper.destroy();
				this.swiper = null;
			}
		},
		created() {
			this.getbanner()
			this.getDictinfo()
		},
		methods: {
			getDictinfo() {

				Dictinfo({
					id: 42
				}).then((res) => {
					console.log(res);
					this.code = res.data.code
				})
			},
			bf(id) {

				if (!localStorage.getItem('token')) {
					this.$confirm('您好，您还没有登录,只能看' + this.code + '分钟哦！', '提示', {
						confirmButtonText: '确定',
						center: true,
						type: 'warning',
						customClass: 'return-visit-message-box',
					}).then(() => {
						this.$router.push({
							name: 'bofang',
							query: {
								id: id
							}
						});
					}).catch(() => {

					});


				} else {
					this.getinfo(id)
				}


			},
			getinfo(id) {
				info().then((res) => {
					if (res.code == 0) {
						this.$router.push({
							name: 'bofang',
							query: {
								id: id
							}
						});
					} else if (res.code == -1) {
						Message.error('账户已在其他设备登录~请重新登录~');
						window.location.reload()
						localStorage.removeItem('token')

					}
				})
			},
			goToUrl(e) {
				// 使用 window.location.href 跳转
				window.open(e);

			},
			scrollRight() {
				// 向右滚动100像素
				this.$refs.scrollContainer.scrollBy({
					left: 300,
					behavior: 'smooth' // 可选，平滑滚动
				});
			},
			scrollToLeft() {
				// 获取滚动容器的DOM引用
				const container = this.$el.querySelector('.saicheng_list');
				// 将scrollLeft设置为0，使容器滚动到最左边
				container.scrollLeft = 0;
			},
			getbanner() {
				banner({
					ad_sort_id: 1
				}).then((res) => {
					console.log(res);
					if (res.code === 0) {
						setTimeout(() => {
							this.bannerlist = res.data
							this.$nextTick(() => {
								this.initSwiper();
							});
						}, 600);

					}
				})
			},
			initSwiper() {
				this.swiper = new Swiper('.swiper-container', {
					loop: true,
					autoplay: true, //自动轮播
					clickable: true,
					pagination: {
						el: '.swiper-pagination',
					},
					paginationStyle: {
						right: '10px' // 根据需要调整位置
					}
				});
			},
			getUpcoming() {
				indeexUpcoming({
					status: 1
				}).then((res) => {
					console.log(res);
					if (res.code === 0) {
						this.Upcominglist = res.data
					}
				})
			},
			del() {
				this.$router.push({
					name: 'schedule',
					params: {
						userId: 123
					}
				});
			}
		},
		mounted() {
			new Swiper('.swiper-container', {
				loop: true,
				autoplay: true, //自动轮播
				clickable: true,
				pagination: {
					el: '.swiper-pagination',
				},
				paginationStyle: {
					right: '10px' // 根据需要调整位置
				}
			});
			localStorage.setItem('searchlist', JSON.stringify(this.searchlist))
			this.getbanner()
			this.getUpcoming()
			window.scrollTo(0, 0);



		}
	};
</script>


<style>
	.el-message-box.return-visit-message-box {
		width: 355px;
		z-index: 99999999;
		padding-bottom: 20px;

		.el-message-box__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 10px;
			z-index: 99999999;
			background: rgba(241, 243, 245, 1);

			.el-message-box__title {
				font-size: 16px;
				font-weight: 500;
				color: rgba(51, 51, 51, 1);
			}

			.el-message-box__headerbtn {
				position: unset;
			}


		}
	}

	.el-carousel__indicators--horizontal {
		bottom: 40px !important;
		left: 50%;
		transform: translateX(-50%);
	}

	.el-carousel__item h3 {
		color: #475669;
		font-size: 18px;
		opacity: 0.75;
		line-height: 300px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}
</style>

<style scoped lang="less">
	.swiper-container {
		width: 100%;
		height: 550px;
		overflow: hidden;
		margin-top: 80px;

	}

	.swiper-slide {
		width: 100%;
		height: 550px;
		text-align: center;
		font-size: 18px;
		background: #fff;

		img {
			width: 100%;
			height: 550px;
		}
	}

	.saicheng {
		width: 78%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-around;
		margin-top: -22px;
		z-index: -1;

	}

	.saicheng_left {
		width: 28px;
		height: 28px;
	}

	.saicheng_list {
		padding-bottom: 10px;
		width: 100%;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
	}



	/* 定制滚动条轨道 */
	.saicheng_list::-webkit-scrollbar-track {
		height: 2px;
		background-color: #f1f1f1;
	}

	/* 定制滚动条滑块 */
	.saicheng_list::-webkit-scrollbar-thumb {
		height: 4px;
		background-color: #FF3C3C;
	}

	/* 定制滚动条的宽度 */
	.saicheng_list::-webkit-scrollbar {
		height: 4px;
		width: 10px;
	}

	.saicheng_more {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 12px;
		color: #020202;
		width: 76px;
		height: 140px;
		background: #FFFFFF;
		border-radius: 8px;
		border: 1px solid #E8E8E8;
		font-style: normal;
		z-index: 9;
		margin-left: 2%;

		img {
			width: 33px;
			height: 30.54px;
		}

		div {
			margin-top: 28px;
		}
	}

	.saicheng_right {
		width: 28px;
		height: 28px;

	}

	.saicheng_item {
		width: 300px;
		height: 140px;
		background: url(../../assets/firstpage/scbgi.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		margin-left: 20px;
		z-index: 9;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 20px;
		padding-right: 20px;
	}

	.schedule_b_video_vs_hv {
		width: 98%;
		margin-top: 8px;
		display: flex;
		align-items: center;
		justify-content: center;

		.countryname {
			width: 160px;
			// background-color: #E8E8E8;
			white-space: normal;
			word-wrap: break-word;
			font-family: PingFangTC, PingFangTC;
			font-weight: 500;
			font-size: 12px;
			color: #333;

		}


		.vsimg {
			width: 30px;
			height: 30px;
		}

		.flag {
			width: 25px;
			height: 25px;
		}

	}




	.schedule_ball {
		width: 76px;
		height: 24px;
		background: #FF3161;
		border-radius: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: PingFangTC, PingFangTC;
		font-weight: 400;
		font-size: 12px;
		color: #FF3161;
		background-color: RGBA(255, 214, 223, 0.8);
		font-style: normal;
		margin-top: 6px;
	}

	.saicheng_time {

		font-family: PingFangTC, PingFangTC;
		font-weight: 500;
		font-size: 14px;
		color: #121212;
		font-style: normal;
		margin-top: 8px;

	}

	.saicheng_jieshao {
		width: 100%;
		height: 30px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;


		img {
			width: 47px;
			height: 40px;

		}
	}

	.saicheng_jieshao>div:nth-of-type(1) {
		width: 33%;
		padding-top: 8px;
		display: flex;
		font-family: PingFangTC, PingFangTC;
		font-weight: 400;
		font-size: 12px;
		color: #333333;
		font-style: normal;
		justify-content: flex-start;
	}

	.saicheng_jieshao>div:nth-of-type(2) {
		width: 33%;
		padding-top: 4px;
		font-family: PingFangTC, PingFangTC;
		font-weight: 600;
		font-size: 14px;
		color: #FFFFFF;
		display: flex;
		justify-content: center;
	}

	.saicheng_jieshao>div:nth-of-type(3) {
		width: 33%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.newbox_b_pc {
		width: 100%;
		height: 750px;
		background-color: var(--n-bg-color);
	}

	.swiper-button-next {
		color: red
	}

	.swiper-button-prev {
		color: red
	}
</style>