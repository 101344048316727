import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
const state = {
  skin: "shallow", //shallow浅色模式  //deep深色模式
};
const mutations = {
  setSkin(state, newValue) {
    state.skin = newValue;
  },
};
export default new Vuex.Store({
  state,
  mutations,
});
