<template>
	<div>
		<div class="bottom_image">
			<div class="bottom">
				<!-- <div class="bottom_left">
					<div class="bottom_t">
						<img src="../../src/assets/firstpage/b1.png" alt="" srcset="" />
						<div>直播赛事网</div>
					</div>
					<div class="bottom_left_b">
						<div>地址 | 北京市朝阳区三元桥9号</div>
						<div style="margin-top: 17px;">地址 | 北京市朝阳区三元桥9号</div>
						<div style="margin-top: 17px;">地址 | 北京市朝阳区三元桥9号</div>
					</div>
				</div> -->
				<div class="bottom_c">
					<div class="bottom_t">
						<img src="../../src/assets/firstpage/b2.png" alt="" srcset="" />
						<div>关注我们</div>
					</div>
					<div class="bottom_c_l">
						<img src="../assets/wx.png" alt="" srcset="" />
						<div class="bottom_c_l_c">
							<div>
								微信

							</div>
							<a style="text-decoration: none;"
								href="javascript:window.location.href='weixin://'">yingyanzb</a>

						</div>
						<img src="../assets/telegram.png" alt="" srcset=""  style="margin-left: 25px;width: 25px;height: 25px;"/>
						<div class="bottom_c_l_c">
							<div>
								telegram
						
							</div>
							<a style="text-decoration: none;"
								href="https://t.me/yingyanzb">yingyanzb</a>
						
						</div>
						<img style="margin-left: 25px;" src="../assets/wb.png" alt="" srcset="" />
						<div class="bottom_c_l_r" style="margin-left:5px;">
							
							<a style="text-decoration: none;"
								href="https://m.weibo.cn/u/7930553668?t=&luicode=10000011&lfid=100103type%253D1%2526q%253D%E9%B9%B0%E7%9C%BC%E7%9B%B4%E6%92%AD202407">微博</a>

							<!-- <div>live:.cid.72fa3b4293bbc881</div> -->
						</div>
						
					</div>
				</div>
				<div class="bottom_r">
					<div class="bottom_t">
						<img src="../../src/assets/firstpage/b3.png" alt="" srcset="" />
						<div>合营账号</div>
					</div>
					<div class="bottom_r_b">
						<div v-for="(item,index) in bottomlist" :key="index">
							<img :src="item.cover" alt="" srcset="" />
							<div>{{item.title}}</div>
						</div>
						
					</div>
				</div>
			</div>
		</div>
		<div class=" bottom_b">
			@文案内容备案编号的具体信息可以在此位置
		</div>

	</div>
</template>

<script>
	import {

		banner,

	} from "../../api/home.js";
	export default {
		data() {
			return {
				cover1: '',
				title1: "",
				cover2: '',
				title2: "",
				bottomlist:[],
				isdack: true
			}
		},
		methods: {
			goToUrl(e) {
				// 使用 window.location.href 跳转
				window.open(e);

			},
			getbanner1() {
				banner({
					ad_sort_id: 11
				}).then((res) => {
					console.log(res)
					this.bottomlist = res.data
					

				})

			},
			// getbanner2() {
			// 	banner({
			// 		ad_sort_id: 12
			// 	}).then((res) => {
					

			// 		this.cover2 = res.data[0].cover
			// 		this.title2 = res.data[0].title
					
			// 	})

			// },
		},
		mounted() {
			this.getbanner1()
			
		}
	};
</script>
<style scoped lang="less">
	.bottom_image {
		width: 100%;
		height: 260px;
		background-color: var(--live-bgc);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.bottom {
		width: 70%;
		margin: 20px auto;
		display: flex;
		align-items: flex-start;
		/* justify-content: space-between; */

	}

	@media screen and (max-width: 1199px) {
		.bottom {
			width: 94%;
			margin: 20px auto;
			display: flex;
			align-items: flex-start;
			/* justify-content: space-between; */

		}
	}

	.bottom_left {
		width: 25%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.bottom_c {
		width: 52%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.bottom_r {
		width:45%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.bottom_t {
		display: flex;
		align-items: center;

		img {
			width: 36px;
			height: 36px;
		}

		div {
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 18px;
			color: var(--n-word-no-color);
			margin-left: 4px;
			font-style: normal;
			text-transform: none
		}
	}

	.bottom_left_b {
		margin-top: 30px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: var(--n-word-no-color);
		font-style: normal;
		text-transform: none;

	}

	.bottom_c_l {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 30px;

		img {
			width: 30px;
			height: 30px;
		}

		.bottom_c_l_c {
			display: flex;
			flex-direction: column;
			margin-left: 7px;
		}

		.bottom_c_l_r {
			display: flex;
			flex-direction: column;
			/* margin-left: 90px; */
		}

		.bottom_c_l_c>:nth-of-type(1) {
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: var(--n-word-no-color);
			font-style: normal;
			text-transform: none;
		}

		.bottom_c_l_c>:nth-of-type(2) {

			font-weight: 400;
			font-size: 11px;
			color: var(--n-word-no-color);
			font-style: normal;
			text-transform: none;
		}

		.bottom_c_l_r>:nth-of-type(1) {
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: var(--n-word-no-color);

			font-style: normal;
			text-transform: none;
		}

		.bottom_c_l_r>:nth-of-type(2) {

			font-weight: 400;
			font-size: 11px;
			color: var(--n-word-no-color);
			font-style: normal;
			text-transform: none;
		}
	}

	.bottom_r_b {
		display: flex;
		margin-top: 30px;

		img {
			width: 80px;
			height: 80px;
		}

		div {
			display: flex;
			flex-direction: column;
			align-items: center;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: var(--n-word-no-color);
			font-style: normal;
			text-transform: none;
			margin-left: 10px;

			div {
				margin-top: 4px;
			}
		}
	}

	.bottom_b {
		width: 100%;
		height: 48px;
		background: #180309;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		font-style: normal;
		text-transform: none;
	}
</style>