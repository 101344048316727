import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/pc/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/live',
		name: 'live',
		component: () => import( /* webpackChunkName: "about" */ '../views/pc/live.vue')
	},
	{
		path: '/schedule',
		name: 'schedule',
		component: () => import( /* webpackChunkName: "about" */ '../views/pc/schedule.vue')
	},
	{
		path: '/news',
		name: 'news',
		component: () => import( /* webpackChunkName: "about" */ '../views/pc/news.vue')
	},
	{
		path: '/newsdel',
		name: 'newsdel',
		component: () => import( /* webpackChunkName: "about" */ '../views/pc/newsdel.vue')
	},
	{
		path: '/search',
		name: 'search',
		component: () => import( /* webpackChunkName: "about" */ '../views/pc/search.vue')
	},{
		path: '/video',
		name: 'video',
		component: () => import( /* webpackChunkName: "about" */ '../views/pc/video.vue')
	},{
		path: '/bofang',
		name: 'bofang',
		component: () => import( /* webpackChunkName: "about" */ '../views/pc/bofang.vue')
	},{
		path: '/agreement',
		name: 'agreement',
		component: () => import( /* webpackChunkName: "about" */ '../views/pc/agreement.vue')
	},{
		path: '/Privacy',
		name: 'Privacy',
		component: () => import( /* webpackChunkName: "about" */ '../views/pc/Privacy.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router