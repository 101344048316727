import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
		path: '/register',
		name: 'register',
		component: () => import( /* webpackChunkName: "about" */ '../views/h5/register.vue')
	}, {
		path: '/',
		name: 'firstpage',
		component: () => import( /* webpackChunkName: "about" */ '../views/h5/firstpage.vue')
	},


	{
		path: '/login',
		name: 'login',
		component: () => import( /* webpackChunkName: "about" */ '../views/h5/login.vue')
	},
	{
		path: '/loginp',
		name: 'loginp',
		component: () => import( /* webpackChunkName: "about" */ '../views/h5/loginp.vue')
	},
	{
		path: '/news',
		name: 'news',
		component: () => import( /* webpackChunkName: "about" */ '../views/h5/news.vue')
	},

	{
		path: '/newsdel',
		name: 'newsdelh5',
		component: () => import( /* webpackChunkName: "about" */ '../views/h5/newsdelh5.vue')
	},
	{
		path: '/mine',
		name: 'mine',
		component: () => import( /* webpackChunkName: "about" */ '../views/h5/mine.vue')
	},

	{
		path: '/search',
		name: 'searchh5',
		component: () => import( /* webpackChunkName: "about" */ '../views/h5/searchh5.vue')
	},
	{
		path: '/livedel',
		name: 'video',
		component: () => import( /* webpackChunkName: "about" */ '../views/h5/video.vue')
	},
	{
		path: '/live',
		name: 'live',
		component: () => import( /* webpackChunkName: "about" */ '../views/h5/live.vue')
	},
	{
		path: '/schedule',
		name: 'schedule',
		component: () => import( /* webpackChunkName: "about" */ '../views/h5/schedule.vue')
	},{
		path: '/agreement',
		name: 'agreement',
		component: () => import( /* webpackChunkName: "about" */ '../views/h5/agreement.vue')
	},{
		path: '/Privacy',
		name: 'Privacy',
		component: () => import( /* webpackChunkName: "about" */ '../views/h5/Privacy.vue')
	},{
		path: '/forgotpassword',
		name: 'forgotpassword',
		component: () => import( /* webpackChunkName: "about" */ '../views/h5/forgotpassword.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router