<template>
	<div>
		<div class="navboxpc">
			<div class="navboxpc_c">
				<!-- logo -->
				<div class="n_logo">
					<img class="n_logo_l" src="../assets/firstpage/logo_l.png" alt="" sizes="" srcset="" />
					<img v-if="dark=='shallow'" class="n_logo_r" src="../../src/assets/firstpage/logo_r.png" alt="" />
					<img v-if="dark=='deep'" class="n_logo_r" src="../../src/assets/firstpage/logo_r1.png" alt="" />
				</div>
				<!-- tab -->

				<div class="n_t">
					<div><router-link :class="select==1?'selecti':'selectno'" to="/">首页</router-link></div>
					<div><router-link :class="select==2?'selecti':'selectno'" to="/live">直播</router-link></div>
					<div><router-link :class="select==3?'selecti':'selectno'" to="/schedule">赛程</router-link></div>
					<div><router-link :class="select==4?'selecti':'selectno'" to="/news">新闻</router-link></div>
				</div>
				<!-- input -->
				<div v-if="select!=5" class="n_i" @click="search()">
					<div>
						点击搜索
					</div>
					<img src="../assets/live/sousuoh.png" alt="" />
				</div>
				<div v-if="select==5" class="n_i1">
				</div>
				<!-- 登录/注册 -->
				<div class="n_l">
					<img v-if="dark=='shallow'" @click="zhezhi()" src="../../src/assets/firstpage/person.png" alt=""
						sizes="" srcset="" />
					<img v-if="dark=='deep'" @click="zhezhi()" src="../../src/assets/firstpage/person1.png" alt=""
						sizes="" srcset="" />
					<div v-if="!token" @click="register()">登录/注册</div>
					<div v-if="token" @click="zhezhi()">已登录</div>
				</div>
			</div>
		</div>
		<div class="overlay" v-if="internalPopup">
			<div class="login" v-if="loginshow">
				<div class="n_logo" style="position: relative;">
					<img @click.stop="internalPopup=false"
						style="position: absolute;left: 200px;top: 2px;cursor: pointer;"
						src="../../src/assets/live/sz2.png" alt="" />
					<img class="n_logo_l" src="../../src/assets/firstpage/logo_l.png" alt="" sizes="" srcset="" />
					<img class="n_logo_r" src="../../src/assets/firstpage/logo_r.png" alt="" />
				</div>
				<div class="input1" style="margin-top: 30px;">
					<div>+86</div>|
					<input type="number" v-model="msg.phone" placeholder="请输入手机号" />
				</div>
				<div v-if="code" class="input2" style="margin-top: 20px;"><input v-model="msg.code" type="number"
						placeholder="请输入验证码" />
					<div v-if="code1" @click.stop="startCountdown()" class="getcode" style="cursor: pointer;">获取验证码
					</div>
					<div v-if="!code1" class="getcode" style="cursor: pointer;">{{remainingTime}}s后重新发送</div>

				</div>
				<div v-if="password" class="input3" style="margin-top: 20px;"><input v-model="msg.password"
						type="password" placeholder="请输入密码" />

				</div>
				<div v-if="passwords" class="input3" style="margin-top: 20px;"><input v-model="msg.passwords"
						type="password" placeholder="请再次输入密码" />

				</div>
				<div v-if="for_code" class="forget">
					<div @click="wj()">忘记密码？</div>
					<div @click="phone()">验证码登录</div>
				</div>
				<div v-if="for_num" class="forget">
					<div @click="wj()">忘记密码？</div>
					<div @click="number()">手机号登录</div>
				</div>
				<div class="submit" v-if="sublogin" @click="tologin()">登录</div>
				<div class="submit" v-if="sucode" @click="tocode()">登录</div>
				<div class="submit" v-if="subzc" @click="toregister()">确认</div>
				<div class="submit" v-if="passwordsub" @click="toregister1()">确认修改</div>
				<div v-if="zhuce" @click="register()" class="register">还没有账号？<span>立即注册</span></div>
				<div
					style="font-size: 12px;margin-top: 10px;display: flex;align-items: center;justify-content: flex-start">
					<el-switch v-model="value" active-color="#13ce66" inactive-color="#eee">
					</el-switch>
					<div style="margin-left: 10px;">登录后30天可免登录 </div>
				</div>
				<div v-if="sub_login" class="register" @click="loginsubmit()">已有账号？<span>立即登录</span></div>
				<div class="agreementh5">登录及接受 <span><router-link to="/agreement">用户协议</router-link></span> 及
					<span><router-link to="/Privacy">隐私授权</router-link></span>
				</div>
			</div>
			<!-- 设置 -->
			<div class="setup" v-if="setup">
				<div class="setups">
					<img @click.stop="internalPopup=false" class="setupsi" src="../../src/assets/live/sz2.png" alt="" />
					<div class="setbox">
						<div class="setbox_l">
							<div @click="minepop=1" :style="minepop==1?'background: #FF4361;color: #FFFFFF;':''">基本信息
							</div>
							<div @click="minepop=2" :style="minepop==2?'background: #FF4361;color: #FFFFFF;':''">我的团队
							</div>
							<div @click="minepop=3" :style="minepop==3?'background: #FF4361;color: #FFFFFF;':''">我的邀请
							</div>
						</div>
						<div class="setbox_r2" v-if='minepop==3'>
							<div class="setbox_r2_sbox">
								<div class="setbox_r1_sbox_t" style="padding-left: 0px;">我的邀请</div>
								<div class="code1">
									<div>我的邀请码</div>
									<div>

										<vue-qr :correctLevel="3" :autoColor="false" colorDark="#000" :logoSrc="logoSrc"
											:text="codeUrl" :size="95" :margin="0" :logoMargin="3"></vue-qr>

									</div>
								</div>
								<div class="code2">
									<div>邀请链接:{{codeUrl}}</div>
									<img @click="copyToClipboard" src="../../src/assets/copy.png" alt="" />
								</div>
							</div>
						</div>
						<div class="setbox_r1" v-if='minepop==2'>
							<div class="setbox_r1_sbox">
								<div class="setbox_r1_sbox_t">我的团队</div>
								<div class="teambox">
									<div v-for="(item,index) in teamlist" :key="index" class="setbox_r1_sbox_b">
										<img v-if="item.avatar" :src="item.avatar" alt="" />
										<img v-else src="../../src/assets/h5/mine.png" alt="" />
										<div>
											<div>注册时间:{{item.create_time}}</div>
											<div style="margin-left: 10px;">注册电话:{{item.mobile}}</div>
										</div>

									</div>
									<div v-if="teamlist.length==0" class="empty">
										<img src="../empty.png" alt="" />
										<div>暂无团队信息!</div>
									</div>
								</div>
							</div>
						</div>
						<div class="setbox_r" v-if='minepop==1'>
							<div style="width: 30%;">
								<div
									style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
									<img style="width: 70px;height: 70px;border-radius: 50%;"
										v-if="info.avatar&&!avatar" :src="info.avatar" alt="" />
									<el-upload v-if="avatar" class="upload-demo" :headers="headers" :action="action"
										:file-list="fileList" :on-success="uploadSuccessApk" multiple :limit="1">
										<el-button v-if="avatar" size="small" icon="el-icon-upload2">上传头像</el-button>

									</el-upload>
									<div @click="avatar=true" v-if="info.avatar||!avatar"
										style="margin: 10px auto;text-align: center;font-size: 12px;color: #FF4361;">
										编辑头像</div>
									<div style="margin-top: 20px;">{{info.mobile}}</div>
								</div>
							</div>

							<div class="setup_right">


								<div class="setup_right_sz">
									<div>夜间模式</div>
									<img @click="toggleTheme()" v-if="dark=='shallow'"
										src="../../src/assets/live/sz4.png" alt="" sizes="" srcset="" />
									<img @click="toggleTheme()" v-if="dark=='deep'" src="../../src/assets/live/sz3.png"
										alt="" sizes="" srcset="" />
								</div>
								<div class="setup_right_sz">
									<div>悬浮窗播放</div>
									<img @click="selectfixed()" v-if="fixed=='nofixed'"
										src="../../src/assets/live/sz4.png" alt="" sizes="" srcset="" />
									<img @click="selectfixed()" v-if="fixed=='fixed'"
										src="../../src/assets/live/sz3.png" alt="" sizes="" srcset="" />
								</div>
								<div @click="loginout()" class="setup_right_sz" style="cursor: pointer;">
									<div>退出登录</div>
									<i class="el-icon-arrow-right" style="margin-right: 20px;"></i>
								</div>
							</div>
						</div>
					</div>


				</div>
			</div>

		</div>



	</div>
</template>

<script>
	import {
		register,
		login,
		info,
		sendRes,
		edit,
		up_password,
		verification_code_login,
		Memberteam
	} from "../../api/home.js";
	import VueQr from 'vue-qr'
	import {
		Message
	} from 'element-ui';
	export default {
		components: {

			VueQr
		},
		props: ['select'],
		data() {
			return {
				teamlist: [],
				logoSrc: new URL('../assets/firstpage/logo_l.png', import.meta.url).href,


				codeUrl: '',

				minepop: 1,
				avatar: false,
				token: localStorage.getItem("token"),
				value: true,
				pageWidth: window.innerWidth,
				action: 'https://api.yylive1.com/api/Member/uploadImage',
				headers: {
					Token: localStorage.getItem("token"),
				},

				fileList: [],

				info: {},
				msg: {
					phone: "",
					password: "",
					code: "",
					passwords: '',
				},
				fixed: '',
				dark: "",
				passwords: true,
				sucode: false,
				subzc: false,
				sublogin: false,
				code: true,
				password: true,
				for_code: true,
				for_num: true,
				zhuce: false,
				passwordsub: false,
				sub_login: true,
				setup: false,
				internalPopup: false,
				code1: true,
				remainingTime: 60, // 初始倒计时秒数
				timer: null // 计时器
			}
		},
		mounted() {
			let codes = this.$route.query.code;
			if (codes) {
				localStorage.setItem('codes', codes)
			}

			this.token = localStorage.getItem("token"),
				this.dark = sessionStorage.getItem("dark");
			console.log(this.dark, "this.dark")
			if (this.dark == null) {
				this.dark = sessionStorage.setItem("dark", 'shallow');
			}
			this.fixed = sessionStorage.getItem("fixed")
			if (this.fixed == null) {
				sessionStorage.setItem("fixed", 'nofixed')
			}

			window.scrollTo(0, 0);

		},
		created() {
			window.addEventListener('resize', this.handleResize);
		},
		destroyed() {
			window.removeEventListener('resize', this.handleResize);
		},
		methods: {
			async copyToClipboard() {
				try {
					await navigator.clipboard.writeText(this.codeUrl);
					Message.success('复制成功!!!');
					console.log('复制成功');
				} catch (err) {
					Message.success('复制失败!!!');

				}
			},
			handleResize() {
				this.pageWidth = window.innerWidth;
				this.fullClick()
			},
			fullClick() {
				if (this.pageWidth > 751) {

					// window.location.reload();
				} else {
					window.location.reload();

				}
			},

			loginout() {
				// localStorage.clear()清除上次登录用户名密码，若此时在地址栏输入项目网址相关页面，仍可以进入项目页面中，故加了下一行
				localStorage.clear()

				//加入该行后会清除缓存，当点击退出按钮后，会清除登录信息缓存


				this.internalPopup = false
				Message.success('您已退出登录!!!');
				this.getinfo()
				this.$router.go(0)
			},
			uploadSuccessApk(file) {
				this.fileList[{
					url: '头像.img',
					name: '头像'
				}]
				this.img(file.data)

			},
			img(data) {
				edit({
					avatar: data
				}).then((res) => {
					console.log(res)

					this.getinfo()
				})
			},
			team() {
				Memberteam().then((res) => {
					console.log(res)
					this.teamlist = res.data

				})
			},
			toregister() {
				let codes = localStorage.getItem('codes')
				if (codes) {
					this.msg.Invitation_code = localStorage.getItem('codes')
				}

				register(this.msg).then((res) => {
					if (res.code == 0) {
						Message.success('注册成功!请登录!!!');
						this.msg = {
							phone: "",
							password: "",
							code: "",
						}
						this.loginsubmit()
					}else if(res.code === -1){
						Message.error(res.msg);
					}
				})
			},
			toregister1() {
				up_password(this.msg).then((res) => {
					if (res.code == 0) {
						Message.success('密码修改成功!请登录!!!');
						this.msg = {
							phone: "",
							password: "",
							code: "",
							passwords: ""
						}
						this.loginsubmit()
					}else if(res.code === -1){
						Message.error(res.msg);
					}
				})
			},
			getinfo() {
				info(this.msg).then((res) => {
					if (res.code == 0) {
						this.avatar = false
						this.team()
						this.info = res.data
						this.codeUrl = "https://www.yylive1.com/?code=" + res.data.code
					}else{
						window.location.reload()
					}
				})
			},
			tologin() {
				login({
					phone: this.msg.phone,
					password: this.msg.password,
				}).then((res) => {
					console.log(res);
					if (res.code === 0) {
						Message.success('已登录!!!');
						this.msg = {
							phone: "",
							password: "",
							code: "",
						}
						this.internalPopup = false
						this.getinfo()
						localStorage.setItem('token', res.data.access_token)
						window.location.reload()
					}else if(res.code === -1){
						Message.error(res.msg);
					}
				})
			},
			tocode() {
				verification_code_login({
					phone: this.msg.phone,
					code: this.msg.code,
				}).then((res) => {
					console.log(res);
					if (res.code === 0) {
						Message.success('已登录!!!');
						this.msg = {
							phone: "",
							password: "",
							code: "",
						}
						this.getinfo()
						this.internalPopup = false
						localStorage.setItem('token', res.data.access_token)
						window.location.reload()
					}else if(res.code === -1){
						Message.error(res.msg);
					}
				})
			},
			selectfixed() {
				if (sessionStorage.getItem("fixed") == 'nofixed') {
					sessionStorage.setItem("fixed", 'fixed')
				} else if (sessionStorage.getItem("fixed") == 'fixed') {
					sessionStorage.setItem("fixed", 'nofixed')
				}
				this.fixed = sessionStorage.getItem("fixed")
				window.location.reload()
			},
			startCountdown() {
				this.code1 = !this.code1
				sendRes({
					phone: this.msg.phone
				}).then((res) => {
					console.log(res);
					if (res.code === 0) {
						Message.success('验证码已发送!');
						this.timer = setInterval(() => {
							if (this.remainingTime > 0) {
								this.remainingTime--;
							} else {
								this.resetCountdown();
								this.code1 = !this.code1
							}
						}, 1000);
					}else{
						this.code1 = !this.code1
						Message.error(res.msg);
					}
			
				})

			},
			resetCountdown() {
				clearInterval(this.timer);
			},
			number() {
				this.internalPopup = true
				this.setup = false
				this.password = true
				this.zhuce = true
				this.code = false
				this.for_code = true
				this.passwordsub = false
				this.for_num = false
				this.passwords = false
				this.sub_login = false
			},
			phone() {
				this.internalPopup = true
				this.setup = false
				this.password = false
				this.zhuce = true
				this.code = true
				this.passwordsub = false
				this.for_code = false
				this.passwords = false
				this.for_num = true
				this.sub_login = false
				this.sucode = true
				this.sublogin = false
				this.subzc = false
			},
			loginsubmit() {
				this.internalPopup = true
				this.setup = false
				this.password = true
				this.passwordsub = false
				this.zhuce = true
				this.passwords = false
				this.code = false
				this.for_code = true
				this.for_num = false
				this.sub_login = false
				this.sublogin = true
				this.subzc = false
				this.passwordsub = false
				this.sucode = false
			},
			register() {
				this.internalPopup = true
				this.setup = false
				this.passwords = true
				this.passwordsub = false
				this.zhuce = false
				this.code = true
				this.password = true
				this.for_code = false
				this.for_num = false
				this.sub_login = true
				this.subzc = true
				this.sublogin = false
				this.sucode = false
				this.loginshow = true

			},
			wj() {
				this.internalPopup = true
				this.setup = false
				this.passwords = true
				this.zhuce = false
				this.code = true
				this.password = true
				this.passwordsub = true
				this.for_code = false
				this.for_num = false
				this.sub_login = true
				this.subzc = false
				this.sublogin = false
				this.sucode = false
			},
			zhezhi() {
				if (localStorage.getItem('token')) {

					this.internalPopup = true
					this.setup = true
					this.loginshow = false
					this.getinfo()

				} else {
					this.register()

				}
			},
			search() {
				this.$router.push({
					name: 'search',
					params: {
						userId: 123
					}
				});
			},
			toggleTheme() {
				if (this.$store.state.skin === "shallow") {
					this.$store.commit("setSkin", "deep");
					document.documentElement.dataset.theme = "dark";
					sessionStorage.setItem("theme", "dark");
				} else {
					this.$store.commit("setSkin", "shallow");
					document.documentElement.dataset.theme = "light";
					sessionStorage.setItem("theme", "light");
				}
				this.dark = this.$store.state.skin
				sessionStorage.setItem("dark", this.$store.state.skin);

			}
		},

	};
</script>
<style scoped lang="less">
	@import url('../../public/theme.css');

	.teambox {
		height: 300px;
		overflow: hidden;
		overflow-y: scroll;

	}

	/* 定制滚动条轨道 */
	.teambox::-webkit-scrollbar-track {
		height: 2px;
		border-radius: 4%;
		background-color: #fff;
	}

	/* 定制滚动条滑块 */
	.teambox::-webkit-scrollbar-thumb {
		border-radius: 4%;
		background-color: #D8D8D8;
	}

	/* 定制滚动条的宽度 */
	.teambox::-webkit-scrollbar {
		height: 4px;
		width: 10px;
	}

	.upload-demo .el-upload-list__item-thumbnail {
		display: none;
	}

	input:focus {

		border: none;
		outline: none;
		background-color: transparent;

	}

	input {
		border: none;
		outline: none;
		background-color: transparent;
	}

	.navboxpc {
		width: 100%;
		height: 80px;
		background-color: var(--n-bg-color);
		position: fixed;
		top: 0px;
		left: 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 99999;
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
	}

	.navboxpc_c {
		width: 80%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	@media screen and (max-width: 1199px) {
		.navboxpc_c {
			width: 96%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.n_t {
		width: 38%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.n_i1 {
		width: 276px;
		height: 31px;
	}

	.n_i {
		width: 276px;
		height: 31px;
		background: #F1F2F4;
		border-radius: 16px;
		display: flex;
		align-items: center;
		justify-content: space-around;

		div {
			width: 200px;
			height: 26px;
			line-height: 26px;
			font-family: PingFangTC, PingFangTC;
			font-weight: 400;
			font-size: 14px;
			color: #333333;
			background: #F1F2F4;
			padding-left: 10px;
			font-style: normal;
		}

		img {
			width: 16px;
			height: 16px;
		}
	}

	.n_l {
		display: flex;
		align-items: center;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: var(--n-word-no-color);
		font-style: normal;
		cursor: pointer;

		div {
			margin-left: 10px;
		}
	}

	.selecti {
		width: 76px;
		height: 30px;
		background: #FF4770;
		border-radius: 18px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 17px;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		font-style: normal;
		margin: 0 0;
	}

	.selectno {
		width: 76px;

		display: flex;
		align-items: center;
		justify-content: center;
		height: 30px;
		color: var(--n-word-no-color);

	}

	a {
		text-decoration: none;
		color: inherit;
	}

	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 1000999;
	}

	.login {
		width: 455px;
		height: 500px;
		background: #FFFFFF;
		position: fixed;
		left: 50%;
		top: 45%;
		transform: translate(-50%, -50%);
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

	}

	.n_logo {
		width: 100px;
		display: flex;
		align-items: center;
		justify-content: center;

	}

	.n_logo_l {
		width: 47px;
		height: 41px;
	}

	.n_logo_r {
		width: 74px;
		height: 20px;
	}

	.submit {
		width: 74%;
		height: 42px;
		background: #FF4670;
		border-radius: 4px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 600;
		font-size: 16px;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		font-style: normal;
		margin-top: 26px;
		cursor: pointer;
	}

	.forget {
		width: 74%;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #848484;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-style: normal;
		margin-top: 18px;
		cursor: pointer;
	}

	.register {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #333333;
		font-style: normal;
		margin-top: 10px;
		cursor: pointer;

	}

	.register>span {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #FF4670;
		font-style: normal;

	}

	.input1 {
		width: 74%;
		height: 42px;
		border-radius: 2px;
		border: 1px solid #D4D2D4;
		display: flex;
		align-items: center;

		div {
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: #333333;

			font-style: normal;
		}

		input {
			margin-left: 10px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: #848484;
			font-style: normal;
		}
	}

	.input2 {
		width: 74%;
		height: 42px;
		border-radius: 2px;

		border: 1px solid #D4D2D4;
		display: flex;
		align-items: center;
		justify-content: space-between;

		input {
			width: 56%;
			margin-left: 10px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: #848484;
			font-style: normal;
		}

		div {
			width: 110px;
			height: 35px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #FF4670;
			border-radius: 4px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: #fff;
			font-style: normal;
			margin-right: 2%;
		}
	}

	.input3 {
		width: 74%;
		height: 42px;
		border-radius: 2px;
		border: 1px solid #D4D2D4;
		display: flex;
		align-items: center;

		input {
			width: 56%;
			margin-left: 10px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: #848484;
			font-style: normal;
		}

	}

	.setup {
		width: 790px;
		height: 460px;
		background-image: url(../../src/assets/3.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-color: #fff;
		position: fixed;
		left: 50%;
		top: 45%;
		transform: translate(-50%, -50%);
		padding-top: 50px;
		border-radius: 8px;


	}

	.setups {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-around;
		position: relative;

	}

	.setupsi {
		position: absolute;
		right: 50px;
		top: 10px;
		cursor: pointer;
	}

	.setupsb {
		width: 100%;
		height: 228px;
		background-image: url(../assets/live/sz1.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: absolute;
		bottom: -145px;
		z-index: -1;

	}

	.setup_left {
		width: 30%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		img {
			width: 100px;
			height: 100px;
			border-radius: 50%;
		}

		div {
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: #333333;
			font-style: normal;
			margin-top: 20px;
		}
	}

	.setup_right {
		width: 65%;

		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.setup_right_name {
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 22px;
		color: #0F194D;
		font-style: normal;
	}

	.setup_right_id {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: #0F194D;
		font-style: normal;
		margin-top: 16px;

		span {
			margin-left: 20px;
		}
	}

	.setup_right_sz {
		margin-top: 12px;
		width: 320px;
		height: 58px;
		background: rgba(255, 255, 255, 0.54);
		border-radius: 7px;
		border: 1px solid rgba(151, 151, 151, 0.2);
		display: flex;
		align-items: center;
		justify-content: space-between;

		div {
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 14px;
			color: #0F194D;
			font-style: normal;
			padding-left: 20px;
		}

		img {
			padding-right: 20px;
			cursor: pointer;
		}
	}

	.agreementh5 {
		font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
		font-weight: 400;
		font-size: 13px;
		color: #7B8787;
		font-style: normal;
		text-transform: none;
		margin-top: 10px;
	}

	.agreementh5>span {
		color: #FF4770;
	}

	.setbox {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.setbox_l {
		width: 88px;
		height: 360px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid rgba(151, 151, 151, 0.2);
	}

	.setbox_l>div {
		width: 88px;
		height: 30px;

		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
		font-size: 12px;
	}

	.setbox_r {
		width: 620px;
		display: flex;
		align-items: flex-start;
	}

	.setbox_r1 {
		width: 620px;
		display: flex;
		align-items: flex-start;
	}

	.setbox_r2 {
		width: 620px;
		display: flex;
		align-items: flex-start;
	}

	.setbox_r2_sbox {
		width: 450px;
		height: 360px;
		border-radius: 4px;
		margin-left: 70px;
	}

	.setbox_r1_sbox {
		width: 450px;
		height: 360px;
		border-radius: 4px;
		border: 1px solid #E0E0E0;
		margin-left: 70px;
	}

	.setbox_r1_sbox_t {
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 18px;
		color: #000000;
		font-style: normal;
		padding-top: 12px;
		line-height: 30px;
		padding-left: 22px;
	}

	.setbox_r1_sbox_b {
		display: flex;
		align-items: center;
		padding-left: 22px;
		padding-top: 10px;
	}

	.setbox_r1_sbox_b>img {
		width: 50px;
		height: 50px;
	}

	.setbox_r1_sbox_b>div {
		padding-left: 20px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #0F194D;
		font-style: normal;
		display: flex;
	}

	.code1 {
		width: 350px;
		padding-left: 4%;
		padding-right: 4%;
		height: 147px;
		background: rgba(248, 248, 252, 0.54);
		border-radius: 7px;
		border: 1px solid rgba(151, 151, 151, 0.2);
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 36px;
	}

	.code1>div {
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 14px;
		color: #0F194D;
		font-style: normal;
	}

	.code1>img {
		width: 114px;
		height: 114px;
	}

	.code2 {
		width: 350px;
		height: 54px;
		padding-left: 4%;
		padding-right: 4%;
		background: rgba(248, 248, 252, 0.54);
		border-radius: 7px;
		border: 1px solid rgba(151, 151, 151, 0.2);
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;
	}

	.code2>div {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #0F194D;
		font-style: normal;
	}

	.code2>img {
		width: 14px;
		height: 14px;
	}.empty {
	width: 100%;
	height: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	div{
		margin-top: 20px;
	}
}
</style>