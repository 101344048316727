import service from '../request/index'


export function register(data) {
	return service({
		url: '/index/register',
		method: "post",
		data
	})
}

export function verification_code_login(data) {
	return service({
		url: '/index/verification_code_login',
		method: "post",
		data
	})
}

export function banner(data) {
	return service({
		url: '/index/banner',
		method: "post",
		data
	})
}

export function login(data) {
	return service({
		url: '/index/login',
		method: "post",
		data
	})
}

export function sendRes(data) {
	return service({
		url: '/index/sendRes',
		method: "post",
		data
	})
}

export function Upcomings(data) {
	return service({
		url: 'index/Upcomings',
		method: "post",
		data
	})
}
export function Upcoming(data) {
	return service({
		url: 'index/Upcoming',
		method: "post",
		data
	})
}

export function Inplays(data) {
	return service({
		url: 'index/Inplays',
		method: "post",
		data
	})
}
export function Inplay(data) {
	return service({
		url: 'index/Inplay',
		method: "post",
		data
	})
}

export function endeds(data) {
	return service({
		url: 'index/endeds',
		method: "post",
		data
	})
}


export function ended(data) {
	return service({
		url: 'index/ended',
		method: "post",
		data
	})
}


export function news(data) {
	return service({
		url: '/index/new',
		method: "post",
		data
	})
}


export function getmatchs(data) {
	return service({
		url: '/index/getmatchs',
		method: "post",
		data
	})
}
export function getmatch(data) {
	return service({
		url: '/index/getmatch',
		method: "post",
		data
	})
}
export function new_info(data) {
	return service({
		url: '/index/new_info',
		method: "post",
		data
	})
}

export function search_record(data) {
	return service({
		url: '/index/search_record',
		method: "post",
		data
	})
}

export function geth5link(data) {
	return service({
		url: '/index/geth5link',
		method: "post",
		data
	})
}

export function DictData(data) {
	return service({
		url: '/index/DictData',
		method: "post",
		data
	})
}
export function info(data) {
	return service({
		url: '/member/info',
		method: "post",
		data
	})
}
export function edit(data) {
	return service({
		url: '/Member/edit',
		method: "post",
		data
	})
}

export function up_password(data) {
	return service({
		url: 'index/up_password',
		method: "post",
		data
	})
}

export function LiveAd(data) {
	return service({
		url: '/index/LiveAd',
		method: "post",
		data
	})
}

export function geth5match(data) {
	return service({
		url: '/index/geth5match',
		method: "post",
		data
	})
}

export function protocol(data) {
	return service({
		url: 'protocol/info',
		method: "post",
		data
	})
}

export function indeexUpcoming(data) {
	return service({
		url: 'index/indeexUpcoming',
		method: "post",
		data
	})
}
export function Dictinfo(data) {
	return service({
		url: '/index/Dictinfo',
		method: "post",
		data
	})
}

export function Memberteam(data) {
	return service({
		url: '/Member/team',
		method: "post",
		data
	})
}
