import Vue from 'vue'
import less from 'less'
import VueMeta from 'vue-meta';
 
Vue.use(VueMeta);
Vue.use(less)

import store from './store'
import Vuex from "vuex";
import VideoPlayer from 'vue-video-player'


require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.use(Vuex);

Vue.config.productionTip = false
const clientWidth = document.documentElement.clientWidth
import App from './App.vue'
import router from './router'
import router1 from './router/index1'


new Vue({
	router: clientWidth < 751 ? router1 : router,
	store,
	render: h => h(App)
}).$mount('#app')